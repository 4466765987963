<template>
  <div class="p-1 bg-white">
    <multi-accounts :openModal="openModal"/>
    <modal-create-account :formTab="form" :type="modalType" :contractType="contractType"/>
    <modal-detail-account :formTab="form" :contractType="contractType" :type="modalType"/>
  </div>
</template>

<script>
import MultiAccounts from './MultiAccounts.vue'
import ModalCreateAccount from './Multi-Account/ModalCreateAccount.vue'
import ModalDetailAccount from './Multi-Account/ModalDetailAccount.vue'

export default {
  name: 'tabs',
  components: { MultiAccounts, ModalCreateAccount, ModalDetailAccount },
  data () {
    return {
      form: {},
      tabIndex: 0,
      loading: {
        first: true
      },
      modalType: '',
      contractType: ''
    }
  },
  watch: {
    tabIndex () {
      this.form = {}
    }
  },
  methods: {
    openModal(data, type, contractType) {
      this.contractType = contractType
      this.modalType = type
      if (data) this.form = data
      else this.form = {}
      this.$bvModal.show(type !== 'detail' ? 'modalCreateAccount' : 'ModalDetailAccount')
    }
  }
}
</script>
