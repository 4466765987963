<template>
  <b-modal
    id="ModalDetailAccount"
    :title="`Información del Courier Cuenta ${formTab.code}`"
    ok-variant="warning"
    modal-class="dialog-900 modal-background--multi-color"
    ok-only
  >
    <div>
      <form-render
        :fields.sync="fields"
        :form.sync="form"
        :key="keyFormRenderDetail"
        ref="formRenderDetail"
        containerButtonsClass="col-sm-12 col-lg-4 container-button"
        @send="onAccept"
      >
        <template #services_title>
          <h2 class="mt-2">Servicios Courier <small class="text-primary">(Desmarque los servicios que desea bloquear en su cuenta).</small></h2>        
        </template>
        <template #fields>
          <div class="container-info">
            <div>
              <span v-for="(field, index) in services" :key="`saved_${index}`" class="m-0-5">
                <b-badge
                  :variant="field.saved ? 'primary outlined-default' : 'outlined-primary'"
                  @click="selectField(field, form.types)" class="mb-1"><i v-if="field.saved"
                    class="fa fa-check-circle"></i> {{
                      field.name }}</b-badge>
              </span>
            </div>
            <div v-if="services.length === 0">
              <b-alert class="mb-0 p-1" show variant="info"><span class="text-primary font-weight-normal"><feather-icon icon="InfoIcon"/> No existen servicios para el courrier {{ formTab.carrier.name }}</span></b-alert>
            </div>
          </div>
        </template>
      </form-render>
    </div>
    <template #modal-footer>
      <b-button variant="warning" @click.prevent="ok()" :disabled="loading || services.length === 0">
        <b-spinner v-show="loading" small></b-spinner>
        {{$t('Guardar')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'modal-detail-multiaccount',
  props: ['formTab', 'contractType', 'type'],
  data() {
    return {
      form: {},
      fields: [],
      keyFormRenderDetail: 0,
      loading: false,
      services: [],
      initalForm: null
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading'
    })
  },
  watch: {
    generalLoading: {
      handler () {
        this.loading = !!this.generalLoading.getCarriersOrganizationShippers
      },
      deep: true
    },
    formTab() {
      if (this.type?.toLowerCase() !== 'detail') return

      this.generateField()
      this.updateFormWithTabData()
    },
    'services'() {
      const fields = this.services.filter(field => field.saved === true)
      this.form.fields = fields.map(field => field.id)
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', name: 'carrier_name', label: 'Courier', containerClass: 'col-md-6  col-sm-12', disabled: true },
        { fieldType: 'FieldInput', name: 'carrier_code', label: 'Code', containerClass: 'col-md-6  col-sm-12', disabled: true },
        { fieldType: 'FieldInput', name: 'carrier_status', label: 'Estado Courier', containerClass: 'col-md-6  col-sm-12', disabled: true },
        { fieldType: 'FieldInput', name: 'services_title', useSlot: true, containerClass: 'col-12 mt-3 border-top' },
        { name: 'fields', useSlot: true, containerClass: 'col-sm-12 mt-1 container-info col-md-12' }
      ]
    },
    updateFormWithTabData() {
      this.form = {
        carrier_name: this.formTab.carrier_name,
        carrier_code: this.formTab.code,
        carrier_status: this.formTab.carrier?.status === 'Habilitado' ? 'Activa' : 'Inactiva'
      }
    },
    selectField(field) {
      this.services = this.services.map(item => {
        if (item.id === field.id) return {...item, saved: !item.saved}
        return item
      })
    },
    generateField () {
      const { blocked_services, services: serv } = this.formTab
      this.services = serv.map((service) => {
        const isBlocked = blocked_services.some((blocked) => blocked.code === service.code)
        return {
          id: service.id,
          name: service.name,
          text: service.name,
          code: service.code,
          saved: !isBlocked
        }
      })
    },
    ok(e) {
      this.$refs.formRenderDetail.checkForm()
    },
    onAccept(form) {
      const blockServices = this.services.filter(serv => !serv.saved)
      const cleanedServices = blockServices.map(service => service.id)

      const isDistributions = this.contractType?.toLowerCase() === 'distribucion'

      const queryParams = {
        blocked_service_ids: cleanedServices,
        code: this.formTab.code,
        contract_type: this.contractType.toLowerCase(),
        data: {},
        carrier_code: this.formTab.carrier.code,
        shipper_id: parseInt(this.$route.params.id),
        is_preferential: this.formTab.is_preferential ?? false, //Este valor debe cambiar a is_preferential
        organization_id: isDistributions ? 10 : parseInt(this.$route.params.id),
        validity_date: this.formTab.validityDate,
        customization: !isDistributions ? { 'default-account': true } : {}, //esto solo deberia de enviarse si es pasarela
        credentials: this.formTab.credentials.length ? this.formTab.credentials : {}
      }
      const headers = {
        'x-carrier' : this.formTab.carrier.code
      }
      this.$store.dispatch('fetchService', {
        name: 'updateCarriersAccount',
        queryParams,
        params: { account_id: this.formTab.id },
        headers,
        onSuccess: this.succesEditAccount,
        onError: () => this.loading = false
      })
    },
    succesEditAccount() {
      this.$bvModal.hide('ModalDetailAccount')
      this.$success(this.$t('msg-exito-guardar'))
      const queryParams = {
        shipper_id : this.$route.params.id,
        contract_type: this.contractType.toLowerCase(),
        page: 1,
        limit: 10
      }
      this.$store.dispatch('fetchService', {
        name: 'getCarriersOrganizationShippers',
        queryParams
      })
    }
  }
}
</script>

<style lang="scss" scoped>
span.badge {
  cursor: pointer;
  transition: all 0.3s ease-in;
}
span.badge.badge-outlined-primary {
  border: 1px solid #064389;
  color: #064389;
}

span.badge.badge-outlined-default {
  border: 1px solid #d3d4d6;
  color: #d3d4d6;
}
.badge {
  padding: 0.6rem 0.6rem;
  border-radius: 0.9rem;
}

.badge i {
  font-size: 20px;
  position: relative;
  margin-right: 10px;
  margin-top: -4px;
  margin-left: -2px;
}

.m-0-5 {
  margin: 0.5rem;
}

</style>